@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: "Poppins-Thin", "Poppins-Light", "Poppins";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: "Poppins-Thin";
  src: url("../public/fonts/Poppins-Thin.woff") format('woff');
  font-weight: normal;
}

@font-face {
  font-family: "Poppins-Light";
  src: url("../public/fonts/Poppins-Light.woff") format('woff');
  font-weight: normal;
}

@font-face {
  font-family: "Poppins-Regular";
  src: url("../public/fonts/Poppins-Regular.woff") format('woff');
  font-weight: normal;
}